import React, { useState } from 'react';
import { always } from 'ramda';
import { useIntl } from 'react-intl';

import { HeroVideoHeadingValue } from '@definitions/common.types';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { getLongestWordLength } from '@utils/helpers';
import { HEADER_TYPE } from '@components/articles/articleHeader/articleHeader.constants';
import { MAX_HEADING_WORD_LENGTH } from '@components/blocks/heroHeading/heroHeading.constants';
import { DEFAULT_BACKGROUND_COLOR } from '@utils/constants';

import MuteIcon from '../../../../assets/images/mute.svg';
import PauseIcon from '../../../../assets/images/pause.svg';
import UnmuteIcon from '../../../../assets/images/unmute.svg';
import PlayIcon from '../../../../assets/images/play.svg';
import {
  Container,
  Headline,
  HeadlineOnImage,
  Masthead,
  Paragraph,
  ImageWrapper,
  PlayerWrapper,
  Player,
  PlayerControls,
  ControlIconButton,
  VideoTopContent,
} from '../heroHeading.styles';

export interface HeroVideoHeadingProps {
  value: HeroVideoHeadingValue;
  isCarousel?: boolean;
}

export const HeroVideoHeading = ({
  value: {
    masthead,
    headline,
    paragraph,
    textColor,
    secondaryTextColor,
    videoUrl,
    backgroundColor = DEFAULT_BACKGROUND_COLOR,
  },
  isCarousel = false,
}: HeroVideoHeadingProps) => {
  const intl = useIntl();
  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(0);
  const isLongWordInHeadline = !!headline && getLongestWordLength(headline) > MAX_HEADING_WORD_LENGTH;
  const playLabel = intl.formatMessage({ id: 'heroVideoHeading.play', defaultMessage: 'Play Video' });
  const pauseLabel = intl.formatMessage({ id: 'heroVideoHeading.pause', defaultMessage: 'Pause Video' });
  const muteLabel = intl.formatMessage({ id: 'heroVideoHeading.mute', defaultMessage: 'Mute Video' });
  const unmuteLabel = intl.formatMessage({ id: 'heroVideoHeading.unmute', defaultMessage: 'Unmute Video' });

  const renderVideo = renderWhenTrue(() => (
    <PlayerWrapper>
      <Player
        url={videoUrl}
        data-testid="video-hero-heading"
        playsinline
        playing={playing}
        loop={isCarousel}
        volume={volume}
        onEnded={() => setPlaying(false)}
        onPause={() => setPlaying(false)}
        muted={!volume}
      />
      <PlayerControls>
        {renderPlayButton(playing)}
        {renderMuteButton(!!volume)}
      </PlayerControls>
    </PlayerWrapper>
  ));

  const renderPlayButton = renderWhenTrueOtherwise(
    always(
      <ControlIconButton onClick={() => setPlaying(false)} aria-label={pauseLabel}>
        <PauseIcon />
      </ControlIconButton>
    ),
    always(
      <ControlIconButton onClick={() => setPlaying(true)} aria-label={playLabel}>
        <PlayIcon />
      </ControlIconButton>
    )
  );

  const renderMuteButton = renderWhenTrueOtherwise(
    always(
      <ControlIconButton onClick={() => setVolume(0)} aria-label={muteLabel}>
        <UnmuteIcon />
      </ControlIconButton>
    ),
    always(
      <ControlIconButton onClick={() => setVolume(1)} aria-label={unmuteLabel}>
        <MuteIcon />
      </ControlIconButton>
    )
  );

  const renderMasthead = renderWhenTrue(() => <Masthead color={textColor}>{masthead}</Masthead>);

  const renderHeadline = renderWhenTrue(() => (
    <Headline color={textColor} isLongWord={isLongWordInHeadline} dangerouslySetInnerHTML={{ __html: headline }} />
  ));

  const renderHeadlineOnImage = renderWhenTrue(() => (
    <HeadlineOnImage
      color={secondaryTextColor}
      isLongWord={isLongWordInHeadline}
      dangerouslySetInnerHTML={{ __html: headline }}
    />
  ));

  return (
    <Container
      data-testid={HEADER_TYPE.HERO_HEADING_FULL_BLEED_VIDEO}
      backgroundColor={backgroundColor}
      isCarousel={isCarousel}
    >
      <VideoTopContent>
        <ImageWrapper wide>
          {renderVideo(!!videoUrl)}
          {renderHeadlineOnImage(!!headline)}
        </ImageWrapper>
      </VideoTopContent>
      {renderMasthead(!!masthead)}
      {renderHeadline(!!headline)}
      <Paragraph dangerouslySetInnerHTML={{ __html: paragraph }} color={textColor} />
    </Container>
  );
};
