import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { allPass, always, complement, equals, ifElse, isEmpty, isNil } from 'ramda';
import { useIntl } from 'react-intl';
import NextLink from 'next/link';

import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { CarouselDrawer } from '@components/carouselDrawer';
import { isNotEmpty, isWhiteColor } from '@utils/helpers';
import { BACKGROUND_STRETCH_TYPE, SLIDE_TYPE } from '@components/heroCarousel/heroCarousel.constants';
import { ArticleSlideValue, ArticleVideoSlideValue, ArtworkSlideValue, Slide } from '@definitions/homepage.types';
import { LinkSetType } from '@definitions/common.types';
import { useImage } from '@hooks/useImage';
import { HeroVideoHeading } from '@components/blocks/heroHeading';
import { COLORS } from '@theme/colors';
import { OptimisedImageWrapper } from '@components/optimisedImageWrapper';

import {
  Caption,
  CaptionDescription,
  CaptionHeader,
  CaptionType,
  CenteredImage,
  CenteredImageLink,
  Container,
  Content,
  FullBleedImage,
  FullBleedImageLink,
  Overlay,
  ReadMoreButton,
  Title,
} from './carouselSlide.styles';

export interface CarouselSlideProps {
  slide: Slide;
  isDragging: boolean;
  tabIndex?: number;
  currentSliderIndex: number;
}

export const CarouselSlide = ({ slide: { type, id, value }, isDragging, currentSliderIndex }: CarouselSlideProps) => {
  const intl = useIntl();
  const { image: desktopImage, mobileImage } = value.backgroundImage || { image: null };
  const imageProps = useImage({ desktopImage, mobileImage });
  const [isActive, setIsActive] = useState(false);
  const tabIndex = isActive ? 0 : -1;
  const slideRef = useRef<HTMLDivElement>(null);
  const isFullBleed =
    (type &&
      [
        SLIDE_TYPE.ARTWORK_FULL_BLEED_IMAGE,
        SLIDE_TYPE.ARTICLE_FULL_BLEED_IMAGE,
        SLIDE_TYPE.FULL_BLEED_VIDEO,
        SLIDE_TYPE.CUSTOM_FULL_BLEED_IMAGE,
      ].includes(type)) ||
    (value && value.backgroundImageStretchType === BACKGROUND_STRETCH_TYPE.FULL_BLEED);
  const isArtwork =
    type &&
    [SLIDE_TYPE.ARTWORK_BLOCK, SLIDE_TYPE.ARTWORK_FULL_BLEED_IMAGE, SLIDE_TYPE.ARTWORK_CENTERED_IMAGE].includes(type);
  const isArticle =
    type &&
    [SLIDE_TYPE.ARTICLE_BLOCK, SLIDE_TYPE.ARTICLE_FULL_BLEED_IMAGE, SLIDE_TYPE.ARTICLE_CENTERED_IMAGE].includes(type);
  const isCustom =
    type &&
    [SLIDE_TYPE.CUSTOM_BLOCK, SLIDE_TYPE.CUSTOM_FULL_BLEED_IMAGE, SLIDE_TYPE.CUSTOM_CENTERED_IMAGE].includes(type);
  const imageLinkAriaLabel = intl.formatMessage({
    id: 'carouselSlide.imageLinkAriaLabel',
    defaultMessage: `Go to ${(value as ArtworkSlideValue)?.artwork?.title}`,
  });

  const { browseLink, link } = value as ArtworkSlideValue;
  const { articleType, largeTitle, showDescription, showSmallTitle } = value as ArticleSlideValue;

  const isValidBrowseLink = allPass([
    complement(isEmpty),
    complement(isNil),
    isNotEmpty('displayText'),
    isNotEmpty('slug'),
  ])(browseLink);
  const isExternalBrowseLink = browseLink && browseLink?.type === LinkSetType.EXTERNAL;
  const linkSlug = isValidBrowseLink && !isExternalBrowseLink ? browseLink?.slug : value.slug || '';
  const isValidExternalLink = isValidBrowseLink && isExternalBrowseLink;

  const handleClick = (e: MouseEvent) => isDragging && e.preventDefault();

  const url = link?.type === LinkSetType.INTERNAL ? `/${link?.slug ?? ''}` : link?.urlPath ?? '';

  const externalLinkProps = {
    href: browseLink?.urlPath,
    target: '_blank',
    rel: 'noopener noreferrer',
    ariaLabel: imageLinkAriaLabel,
    onClick: handleClick,
  };

  useEffect(() => {
    const slideElement = slideRef.current?.parentElement?.parentElement;
    const active = slideElement?.classList.contains('slick-active') || false;
    setIsActive(active);
  }, [currentSliderIndex]);

  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  const renderFullBleedImage = renderWhenTrueOtherwise(
    always(
      <FullBleedImageLink {...externalLinkProps} tabIndex={tabIndex}>
        <OptimisedImageWrapper customWidth={windowWidth}>
          <FullBleedImage
            style={{ cursor: url ? 'pointer' : 'default' }}
            onClick={() => window.history.pushState({}, '', `${url || ''}`)}
            src={imageProps.src}
            alt={imageProps.alt}
          />
        </OptimisedImageWrapper>
      </FullBleedImageLink >
    ),
    always(
      <NextLink href={url || ''} passHref>
        <FullBleedImageLink aria-label={imageLinkAriaLabel} onClick={handleClick} tabIndex={tabIndex}>
          <OptimisedImageWrapper customWidth={windowWidth}>
            <FullBleedImage
              style={{ cursor: url ? 'pointer' : 'default' }}
              onClick={() => window.history.pushState({}, '', `${url || ''}`)}
              src={imageProps.src}
              alt={imageProps.alt}
            />
          </OptimisedImageWrapper>
        </FullBleedImageLink>
      </NextLink>
    )
  );

  const renderCenteredImage = renderWhenTrueOtherwise(
    always(
      <CenteredImageLink {...externalLinkProps}>
        <OptimisedImageWrapper customWidth={windowWidth}>
          <CenteredImage
            style={{ cursor: url ? 'pointer' : 'default' }}
            onClick={() => window.history.pushState({}, '', `${url || ''}`)}
            src={imageProps.src}
            alt={imageProps.alt}
          />
        </OptimisedImageWrapper>
      </CenteredImageLink>
    ),
    always(
      <NextLink href={url || ''} passHref>
        <CenteredImageLink
          bottomSpace={isArtwork}
          aria-label={imageLinkAriaLabel}
          onClick={handleClick}
          tabIndex={tabIndex}
        >
          <OptimisedImageWrapper customWidth={windowWidth}>
            <CenteredImage
              style={{ cursor: url ? 'pointer' : 'default' }}
              onClick={() => window.history.pushState({}, '', `${url || ''}`)}
              src={imageProps.src} alt={imageProps.alt} />
          </OptimisedImageWrapper>
        </CenteredImageLink>
      </NextLink>
    )
  );

  const renderImage = ifElse(
    equals(true),
    () => renderFullBleedImage(isValidExternalLink),
    () => renderCenteredImage(isValidExternalLink)
  );

  const renderArtworkTitle = renderWhenTrue(() => (
    <Title color={(value as ArtworkSlideValue).titleColor}>{value.title}</Title>
  ));

  const renderLargeTitle = renderWhenTrue(() => (
    <Title color={(value as ArticleSlideValue).largeTitleColor}>{largeTitle}</Title>
  ));

  const renderArticleType = renderWhenTrue(() => <CaptionType>{articleType}:</CaptionType>);

  const renderDrawer = renderWhenTrue(() => {
    const { drawer, artwork, browseLink } = value as ArtworkSlideValue;
    return (
      <CarouselDrawer
        drawer={drawer}
        artwork={artwork}
        slug={value.slug || ''}
        withThumbnail={isArtwork && isFullBleed}
        withDrawerCaption={isArtwork && isFullBleed}
        darkerBackground={isWhiteColor((value as ArtworkSlideValue).backgroundColor)}
        browseLink={browseLink}
        tabIndex={tabIndex}
      />
    );
  });

  const renderCaptionHeader = renderWhenTrue(() => (
    <CaptionHeader>
      {renderArticleType(articleType && isArticle)}
      {value.title}
    </CaptionHeader>
  ));

  const renderCaptionDescription = renderWhenTrue(() => (
    <CaptionDescription>
      {(value as ArticleSlideValue).description}
      {
        url &&
        <ReadMoreButton
          {...link}
          tabIndex={tabIndex}
          slug={link?.slug || ''}
          displayText={
            link?.displayText
              ? link.displayText
              : intl.formatMessage({ id: 'carouselSlide.readMoreLinkDefaultText', defaultMessage: 'Read more' })
          }
          color={'textColor' in value ? value.textColor : undefined}
        />
      }
    </CaptionDescription>
  ));

  const renderCaption = renderWhenTrue(() => (
    <Caption color={'textColor' in value ? value.textColor : undefined}>
      {renderCaptionHeader(showSmallTitle)}
      {renderCaptionDescription(showDescription)}
    </Caption>
  ));

  const renderOverlay = renderWhenTrue(() => <Overlay color={value.fadeColor || COLORS.BLACK} />);

  const renderVideo = renderWhenTrue(() => (
    <HeroVideoHeading value={value as ArticleVideoSlideValue} isCarousel={true} />
  ));

  return (
    <Container
      backgroundColor={'backgroundColor' in value ? value.backgroundColor : undefined}
      data-testid={`SLIDE-${id}`}
      ref={slideRef}
    >
      <Content>
        {renderVideo(isFullBleed && !desktopImage)}
        {renderImage(isFullBleed && !!desktopImage)}
        {renderOverlay(value.fade)}
        {renderArtworkTitle(isArtwork && !!value.title)}
        {renderDrawer(isArtwork)}
        {renderLargeTitle((isArticle || isCustom) && !!largeTitle)}
        {renderCaption(isArticle || isCustom)}
      </Content>
    </Container>
  );
};
