import React, { ReactNode } from 'react';

import { ARROW_TYPE } from '@utils/constants';

import { ArrowWrapper } from './heroCarousel.styles';

export interface CarouselArrowProps {
  arrowType: ARROW_TYPE;
  arrowColor: string;
  arrowVisible: ARROW_TYPE | null;
  label: string;
  children: ReactNode;
  onClick?: () => void;
}

export const CarouselArrow = ({
  arrowType,
  arrowColor,
  arrowVisible,
  children,
  label,
  onClick,
}: CarouselArrowProps) => (
  <ArrowWrapper
    arrowType={arrowType}
    arrowColor={arrowColor}
    isVisible={arrowType === arrowVisible}
    aria-label={label}
    onClick={onClick}
  >
    {children}
  </ArrowWrapper>
);
