import styled, { css } from 'styled-components';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';
import { defaultButtonStyles } from '@components/form';
import { NARROW_CONTAINER_WIDTH } from '@components/blocks';

import AddSVG from '../../assets/images/add.svg';

export const Container = styled.div`
  position: absolute;
  bottom: 52px;
  width: calc(100% - 20px);
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  pointer-events: none;

  ${media.tablet} {
    width: ${NARROW_CONTAINER_WIDTH}px;
    pointer-events: auto;
  }
`;

export const DrawerHeader = styled.div`
  height: 49px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  transition: background-color ease-in-out;

  ${media.tablet} {
    height: 44px;
  }
`;

const captionLargeStyles = css`
  ${typography({ size: FONT_SIZE.M })};
  transform: translateY(-10px);
`;

export const Caption = styled.p<{ large?: boolean }>`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  padding: 0;
  margin: 0 0 6px;
  text-align: center;
  transition: color 0.5s ease-in-out;

  ${({ large }) => (large ? captionLargeStyles : null)};
`;

export const ArtworkDetails = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  ${media.tablet} {
    flex-flow: row nowrap;
  }
`;

export const DetailElement = styled.div<{ uppercase?: boolean }>`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  color: ${COLORS.WHITE};
  margin: 0 ${OFFSET.XS};
  padding: 0;
  transition: color 0.5s ease-in-out;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : null)};

  ${media.tablet} {
    display: flex;
    align-items: flex-start;
    height: 15px;
  }
`;

export const ArtworkAuthor = styled(DetailElement)`
  white-space: nowrap;
`;

export const ArtworkTitleAndYear = styled(DetailElement)`
  text-align: center;

  ${media.tablet} {
    white-space: nowrap;
    overflow: hidden;

    span:first-of-type {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

export const DrawerContent = styled.div`
  width: 100%;
  height: 396px;
  background-color: ${COLORS.WHITE};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  padding: 0 12px;
`;

export const DrawerFooter = styled.div`
  width: 100%;
  background-color: ${COLORS.WHITE};
  padding: 4px 12px 12px;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  height: ${OFFSET.M};
`;

export const Link = styled.a`
  ${defaultButtonStyles};
  width: 50%;
  text-transform: uppercase;
  background: none;
  white-space: pre;

  &:nth-of-type(2) {
    border-left: none;
  }

  ${media.desktop} {
    ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  }
`;

export const AddIconWrapper = styled(DetailElement)`
  display: none;

  ${media.tablet} {
    display: block;
  }
`;

export const AddIcon = styled(AddSVG)`
  width: 11px;
  height: 100%;
  margin-left: 7px;
  transition: opacity 0.5s ease-in-out;
`;

export const DrawerHeaderInner = styled.div<{ color?: string }>`
  transform: translateY(-9px);
  transition: transform ease-in-out;
  max-width: 100%;

  ${Caption} {
    color: ${({ color }) => (color ? color : COLORS.WHITE)};
  }

  ${DetailElement} {
    color: ${({ color }) => (color ? color : COLORS.WHITE)};
  }

  ${AddIcon} {
    fill: ${({ color }) => (color ? color : COLORS.WHITE)};

    path {
      stroke: ${({ color }) => (color ? color : COLORS.WHITE)};
    }
  }
`;

export const DrawerInner = styled.div<{ withThumbnail: boolean; darkerBackground: boolean; innerHeight?: number }>`
  width: 100%;
  height: 43px;
  transition: height ease-in-out;

  &,
  ${DrawerHeader}, ${DrawerHeaderInner}, ${AddIcon} {
    transition-duration: ${({ withThumbnail }) => (withThumbnail ? '0.5s' : '0.3s')};
  }

  ${DrawerContent}, ${DrawerFooter} {
    background-color: ${({ darkerBackground }) => (darkerBackground ? COLORS.WILD_SAND : COLORS.WHITE)};
  }

  &:focus-within,
  &:hover {
    height: ${({ innerHeight }) => innerHeight}px;
    ${media.tablet} {
      ${DetailElement}, ${Caption} {
        color: ${COLORS.BLACK};
      }

      ${DrawerHeader} {
        background-color: ${({ darkerBackground }) => (darkerBackground ? COLORS.WILD_SAND : COLORS.WHITE)};
      }

      ${DrawerHeaderInner} {
        transform: ${({ withThumbnail }) => (withThumbnail ? 'translateY(calc(100% - 8px))' : 'translateY(-11px)')};
      }

      ${AddIcon} {
        opacity: 0;
      }
    }
  }
`;

export const ImageLink = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ThumbnailImage = styled.img`
  width: 260px;
  height: 260px;
  object-fit: contain;
`;
