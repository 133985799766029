import styled from 'styled-components';

import { COLORS } from '@theme/colors';
import { ReadMoreButton as ReadMoreButtonComponent } from '@components/readMoreButton';
import { NARROW_CONTAINER_WIDTH } from '@components/blocks';
import { media } from '@theme/media';
import { OverlayProps } from '@definitions/homepage.types';
import { FONT_SIZE, LINE_HEIGHT, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')};
  position: relative;
  height: 75vh;

  ${media.mobile} {
    height: 90vh;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
`;

export const ReadMoreButton = styled(ReadMoreButtonComponent)`
  display: inline-block;
  margin-left: 4px;
`;

export const Caption = styled.div<{ color?: string }>`
  width: calc(100% - 30px);
  max-width: 100%;
  position: initial;
  bottom: 128px;
  margin-bottom: 46px;
  color: ${({ color }) => (color ? color : 'black')};
  z-index: 1;

  ${media.tablet} {
    width: calc(${NARROW_CONTAINER_WIDTH}px + ${OFFSET.M} * 4);
  }

  ${ReadMoreButton} {
  }
`;

export const FullBleedImage = styled.img`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const FullBleedImageLink = styled.a`
  position: absolute;
  display: flex;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CenteredImageLink = styled.a<{ bottomSpace?: boolean }>`
  width: calc(100% - ${OFFSET.M} * 2);
  flex-grow: 1;
  position: relative;
  display: flex;
  padding: 0;
  margin: calc(${OFFSET.M} * 2.5) 0 ${({ bottomSpace }) => (bottomSpace ? OFFSET.XL : OFFSET.M)};

  ${media.tablet} {
    width: calc(100% - ${OFFSET.M} * 6);
    margin: calc(${OFFSET.M} * 3) 0 ${({ bottomSpace }) => (bottomSpace ? OFFSET.XL : OFFSET.M)};
  }
`;

export const CenteredImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: contain;
`;

export const Title = styled.div<{ color?: string }>`
  ${typography({ size: FONT_SIZE.L, height: LINE_HEIGHT.S })};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: none;
  color: ${({ color }) => (color ? color : COLORS.BLACK)};

  ${media.tablet} {
    ${typography({ size: FONT_SIZE.XXL, height: LINE_HEIGHT.S })};
  }
`;

export const CaptionHeader = styled.h2`
  ${typography({})};
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  outline: 1px solid blue;

  ${media.tablet} {
    ${typography({ size: FONT_SIZE.M })};
  }
`;

export const CaptionDescription = styled.div`
  ${typography({})};
  text-align: center;
  width: 100%;
  margin: 0 0 ${OFFSET.XS};

  ${media.tablet} {
    ${typography({ size: FONT_SIZE.M })};
  }
`;

export const CaptionType = styled.span`
  margin-right: ${OFFSET.XS};
`;

export const Overlay = styled.div<OverlayProps>`
  display: block;
  height: 25%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: ${(props) => `linear-gradient(180deg, rgba(255,255,255,0) 0%, ${props.color} 100%)`};
  opacity: 0.75;
`;
