import React, { useRef, useState } from 'react';

import { HEADER_TYPE } from '@components/articles/articleHeader/articleHeader.constants';
import { OptimisedImageWrapper } from '@components/optimisedImageWrapper';
import { MAX_HEADING_WORD_LENGTH } from '@components/blocks/heroHeading/heroHeading.constants';
import { HeroImageHeadingValue } from '@definitions/common.types';
import { renderWhenTrue } from '@utils/rendering';
import { getLongestWordLength } from '@utils/helpers';
import { DEFAULT_BACKGROUND_COLOR } from '@utils/constants';
import { useImage } from '@hooks/useImage';
import { useWindowListener } from '@hooks/useWindowListener';

import {
  FullBleedImage,
  Container,
  Headline,
  HeadlineOnImage,
  CenteredImage,
  Masthead,
  Paragraph,
  ImageWrapper,
  TopContent,
} from '../heroHeading.styles';

export interface HeroImageHeadingProps {
  value: HeroImageHeadingValue;
  wide?: boolean;
}

export const HeroImageHeading = ({
  value: {
    masthead,
    headline,
    paragraph,
    textColor,
    secondaryTextColor = '',
    image: desktopImage,
    mobileImage,
    backgroundColor = DEFAULT_BACKGROUND_COLOR,
  },
  wide = true,
}: HeroImageHeadingProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageHeight, setImageHeight] = useState(0);
  const image = useImage({ desktopImage, mobileImage });
  const isLongWordInHeadline = !!headline && getLongestWordLength(headline) > MAX_HEADING_WORD_LENGTH;
  const setContainerHeight = () => {
    setTimeout(() => {
      setImageHeight(imageRef.current?.height || 0);
    });
  };

  const renderImage = renderWhenTrue(() => {
    return (
      <OptimisedImageWrapper>
        {wide ? (
          <FullBleedImage
            src={image.src}
            srcSet={image.srcSet}
            alt={image.alt}
            ref={imageRef}
            onLoad={setContainerHeight}
          />
        ) : (
          <CenteredImage
            src={image.src}
            srcSet={image.srcSet}
            alt={image.alt}
            ref={imageRef}
            onLoad={setContainerHeight}
          />
        )}
      </OptimisedImageWrapper>
    );
  });

  useWindowListener('resize', setContainerHeight, { throttle: 200 });

  const renderMasthead = renderWhenTrue(() => <Masthead color={textColor}>{masthead}</Masthead>);
  const renderHeadline = renderWhenTrue(() => (
    <Headline color={textColor} isLongWord={isLongWordInHeadline} dangerouslySetInnerHTML={{ __html: headline }} />
  ));
  const renderHeadlineOnImage = renderWhenTrue(() => (
    <HeadlineOnImage
      color={secondaryTextColor}
      isLongWord={isLongWordInHeadline}
      dangerouslySetInnerHTML={{ __html: headline }}
    />
  ));

  return (
    <Container
      data-testid={wide ? HEADER_TYPE.HERO_HEADING_FULL_BLEED_IMAGE : HEADER_TYPE.HERO_HEADING_CENTERED_IMAGE}
      backgroundColor={backgroundColor}
    >
      <TopContent style={{ height: imageHeight }}>
        <ImageWrapper wide={wide}>
          {renderImage(!!image)}
          {renderHeadlineOnImage(!!headline && wide)}
        </ImageWrapper>
      </TopContent>
      {renderMasthead(!!masthead)}
      {renderHeadline(!!headline)}
      <Paragraph dangerouslySetInnerHTML={{ __html: paragraph }} color={textColor} />
    </Container>
  );
};
