import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import NextLink from 'next/link';
import { allPass, always, complement, isEmpty, isNil } from 'ramda';

import { SlideArtwork } from '@definitions/artworks.types';
import { CAPTION_SIZE } from '@components/heroCarousel/heroCarousel.constants';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { formatStorePrice, getFullArtistName, getImageSrc, isNotEmpty } from '@utils/helpers';
import {
  CAROUSEL_DRAWER_INNER_TEST_ID,
  CAROUSEL_DRAWER_TEST_ID,
} from '@components/carouselDrawer/carouselDrawer.constants';
import { SlideDrawer } from '@definitions/homepage.types';
import { ROUTE } from '@utils/constants';
import { LinkSet, LinkSetType } from '@definitions/common.types';
import { OptimisedImageWrapper } from '@components/optimisedImageWrapper';

import {
  ActionsContainer,
  AddIcon,
  AddIconWrapper,
  ArtworkAuthor,
  ArtworkDetails,
  ArtworkTitleAndYear,
  Caption,
  Container,
  DetailElement,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderInner,
  DrawerInner,
  ImageLink,
  Link,
  ThumbnailImage,
} from './carouselDrawer.styles';

export interface CarouselDrawerProps {
  drawer: SlideDrawer;
  artwork: SlideArtwork;
  withThumbnail: boolean;
  withDrawerCaption: boolean;
  slug: string;
  tabIndex?: number;
  darkerBackground?: boolean;
  browseLink?: LinkSet;
}

export const CarouselDrawer = ({
  drawer,
  artwork,
  darkerBackground = false,
  slug,
  withThumbnail,
  withDrawerCaption,
  browseLink,
  tabIndex = 0,
}: CarouselDrawerProps) => {
  const intl = useIntl();
  const drawerInnerRef = useRef<HTMLDivElement | null>(null);
  const [drawerInnerHidden, setDrawerInnerHidden] = useState(true);

  const getImageProps = () => {
    if (drawer.thumbnailImage) {
      const src = getImageSrc(drawer.thumbnailImage) || '';
      const alt = drawer.thumbnailImage.alt || '';

      return { src, alt };
    }

    return null;
  };

  const renderThumbnailImage = renderWhenTrue(() => {
    const imageProps = getImageProps();
    const imageLinkAriaLabel = intl.formatMessage({
      id: 'carouselDrawer.imageLinkAriaLabel',
      defaultMessage: `Go to ${artwork.title}`,
    });

    return (
      <DrawerContent>
        <NextLink href={`/${slug}`} passHref>
          <ImageLink
            onClick={() => window.history.pushState({}, '', `/${slug}`)}
            aria-label={imageLinkAriaLabel}
            tabIndex={tabIndex}
          >
            <OptimisedImageWrapper>
              <ThumbnailImage {...imageProps} />
            </OptimisedImageWrapper>
          </ImageLink>
        </NextLink>
      </DrawerContent>
    );
  });

  const renderCaption = renderWhenTrue(() => (
    <Caption large={drawer.displayOption === CAPTION_SIZE.LARGE}>{drawer.caption}</Caption>
  ));

  const renderPrice = renderWhenTrue(() => (
    <DetailElement>{!!artwork.price && formatStorePrice(artwork.price)}</DetailElement>
  ));

  const isValidBrowseLink = allPass([
    complement(isEmpty),
    complement(isNil),
    isNotEmpty('displayText'),
    isNotEmpty('slug'),
  ])(browseLink);

  const renderBrowseLinkType = renderWhenTrueOtherwise(
    always(
      <NextLink href={`/${browseLink?.slug || ''}`} passHref>
        <Link onClick={() => window.history.pushState({}, '', `/${browseLink?.slug || ''}`)} tabIndex={tabIndex}>
          {browseLink?.displayText}
        </Link>
      </NextLink>
    ),
    always(
      <Link href={browseLink?.urlPath} target="_blank" rel="noopener noreferrer" tabIndex={tabIndex}>
        {browseLink?.displayText}
      </Link>
    )
  );

  const renderBrowseLink = renderWhenTrueOtherwise(
    always(renderBrowseLinkType(browseLink?.type && browseLink?.type === LinkSetType.INTERNAL)),
    always(
      <NextLink href={ROUTE.ARTWORKS} passHref>
        <Link onClick={() => window.history.pushState({}, '', ROUTE.ARTWORKS)} tabIndex={tabIndex}>
          <FormattedMessage id="carouselDrawer.browseArtworksLink" defaultMessage="Browse:  all artworks" />
        </Link>
      </NextLink>
    )
  );

  return (
    <Container data-testid={CAROUSEL_DRAWER_TEST_ID}>
      <DrawerInner
        data-testid={CAROUSEL_DRAWER_INNER_TEST_ID}
        innerHeight={drawerInnerRef?.current?.offsetHeight}
        withThumbnail={withThumbnail}
        darkerBackground={darkerBackground}
        onMouseEnter={() => setDrawerInnerHidden(false)}
        onMouseLeave={() => setDrawerInnerHidden(true)}
        aria-hidden={drawerInnerHidden}
      >
        <div ref={drawerInnerRef}>
          <DrawerHeader>
            <DrawerHeaderInner color={drawer.textColor}>
              {renderCaption(!!drawer.caption && withDrawerCaption)}
              <ArtworkDetails>
                <ArtworkAuthor uppercase>{getFullArtistName(artwork.artist)}:</ArtworkAuthor>
                <ArtworkTitleAndYear>
                  <span>
                    <i>{artwork.title}</i>
                  </span>
                  , {artwork.year}
                </ArtworkTitleAndYear>
                {renderPrice(!!artwork.price)}
                <AddIconWrapper>
                  <AddIcon />
                </AddIconWrapper>
              </ArtworkDetails>
            </DrawerHeaderInner>
          </DrawerHeader>
          {renderThumbnailImage(withThumbnail)}
          <DrawerFooter>
            <ActionsContainer>
              <NextLink href={`/${slug}`} passHref>
                <Link onClick={() => window.history.pushState({}, '', `/${slug}`)} tabIndex={tabIndex}>
                  <FormattedMessage id="carouselDrawer.viewArtworkLink" defaultMessage="Explore:  artwork" />
                </Link>
              </NextLink>
              {renderBrowseLink(isValidBrowseLink)}
            </ActionsContainer>
          </DrawerFooter>
        </div>
      </DrawerInner>
    </Container>
  );
};
