import styled, { css } from 'styled-components';
import SliderComponent from 'react-slick';

import { COLORS } from '@theme/colors';
import { NARROW_CONTAINER_WIDTH } from '@components/blocks';
import { media } from '@theme/media';
import { slickStyles } from '@components/common/slick.styles';
import { ARROW_TYPE } from '@utils/constants';
import { FONT_SIZE, LINE_HEIGHT, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div<{ height?: string }>`
  width: 100%;
  position: relative;
  ${slickStyles};
  height: 75vh;

  ${media.mobile} {
    height: 90vh;
  }
`;

export const Slider = styled(SliderComponent)`
  height: 100%;
  position: relative;

  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }

  .slick-cloned {
    pointer-events: none;
  }

  .slick-dots {
    bottom: 23px;
    width: calc(100% - 20px);
    max-width: 100%;
    left: 10px;
    display: flex !important;
    margin: 0;
    z-index: 1;

    ${media.tablet} {
      width: ${NARROW_CONTAINER_WIDTH}px;
      left: calc(50% - ${NARROW_CONTAINER_WIDTH}px / 2);
    }

    li {
      width: auto;
      flex-grow: 1;
      height: 25px;
      position: relative;
      margin: 0;

      &.slick-active button:before {
        background-color: black;
      }

      button {
        width: 100%;
        height: 100%;
        padding: 0;

        &:before {
          content: '';
          width: 100%;
          height: 3px;
          margin: 11px 0;
          display: block;
          background-color: ${COLORS.BLACK};
          opacity: 0;
        }

        &:focus {
          outline: auto;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
`;

export const Slide = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')};
  position: relative;
  height: 75vh;

  ${media.mobile} {
    height: 90vh;
  }

  &:focus {
    outline: none;
  }
`;

export const FullBleedImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SlideContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
`;

export const ImageWrapper = styled.div<{ largerBottom?: boolean }>`
  margin-top: calc(${OFFSET.M} * 3);
  width: calc(100% - ${OFFSET.M} * 6);
  flex-grow: 1;
  position: relative;
  margin-bottom: ${({ largerBottom }) => (largerBottom ? OFFSET.XL : OFFSET.M)};
`;

export const CenteredImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: contain;
`;

export const Caption = styled.div<{ color?: string }>`
  width: calc(${NARROW_CONTAINER_WIDTH}px + ${OFFSET.M} * 4);
  max-width: 100%;
  margin-bottom: 146px;
  color: ${({ color }) => (color ? color : 'black')};
  z-index: 1;
`;

export const CaptionTitle = styled.h2`
  ${typography({ size: FONT_SIZE.M })};
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
`;

export const CaptionCopy = styled.p`
  ${typography({ size: FONT_SIZE.M })};
  text-align: center;
  width: 100%;
  margin: 0 0 ${OFFSET.XS};
`;

export const Title = styled.div<{ color?: string }>`
  ${typography({ size: FONT_SIZE.XXL, height: LINE_HEIGHT.S })};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  color: ${({ color }) => (color ? color : COLORS.BLACK)};
`;

export const CaptionBrow = styled.span`
  margin-right: ${OFFSET.XS};
`;

export const SliderScrollBar = styled.div`
  position: absolute;
  bottom: 34px;
  width: calc(100% - 20px);
  max-width: 100%;
  left: 10px;
  margin: 0;
  height: 3px;
  background-color: ${COLORS.WILD_SAND};
  pointer-events: none;

  ${media.tablet} {
    width: ${NARROW_CONTAINER_WIDTH}px;
    left: calc(50% - ${NARROW_CONTAINER_WIDTH}px / 2);
  }
`;

export const Thumb = styled.div<{ slidersCount: number; index: number }>`
  height: 100%;
  width: ${({ slidersCount }) => (slidersCount ? `calc(100% / ${slidersCount})` : 0)};
  position: relative;
  background-color: ${COLORS.BLACK};
  transition: transform 0.6s;
  transform: translate3d(${({ index }) => (index ? `calc(100% * ${index})` : 0)}, 0, 0);

  ${media.tablet} {
    width: ${({ slidersCount }) => (slidersCount ? `calc(${NARROW_CONTAINER_WIDTH}px / ${slidersCount})` : 0)};
    transform: translate3d(
      ${({ index, slidersCount }) => (index ? `calc(${NARROW_CONTAINER_WIDTH}px / ${slidersCount} * ${index})` : 0)},
      0,
      0
    );
  }
`;

interface ArrowProps {
  arrowColor: string;
  isVisible: boolean;
  arrowType: ARROW_TYPE;
}

export const arrowsWrapperStyles = css<ArrowProps>`
  width: 45px;
  height: 45px;
  cursor: pointer;
  z-index: 2;
  visibility: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;

  svg {
    stroke: ${({ arrowColor }) => arrowColor};
  }

  ${media.desktop} {
    visibility: visible;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

    &:focus {
      opacity: 1;
    }
  }
`;

export const ArrowWrapper = styled.button<ArrowProps>`
  ${arrowsWrapperStyles};

  ${({ arrowType }) => (arrowType === ARROW_TYPE.PREV ? 'left: 32px' : 'right: 32px')};
`;
