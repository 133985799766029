export const HERO_CAROUSEL_TEST_ID = 'hero-carousel';
export const SLIDER_SCROLLBAR_TEST_ID = 'slider-scrollbar';

export const AUTOPLAY_TIME = 3000;
export const SLIDER_SPEED = 600;

export enum BACKGROUND_STRETCH_TYPE {
  CENTERED = 'centered',
  FULL_BLEED = 'full_bleed',
}

export enum SLIDE_TYPE {
  ARTWORK_BLOCK = 'artworkBlock',
  ARTICLE_BLOCK = 'articleBlock',
  CUSTOM_BLOCK = 'customBlock',
  ARTWORK_FULL_BLEED_IMAGE = 'artworkFullBleedImage',
  ARTWORK_CENTERED_IMAGE = 'artworkCenteredImage',
  ARTICLE_FULL_BLEED_IMAGE = 'articleFullBleedImage',
  ARTICLE_CENTERED_IMAGE = 'articleCenteredImage',
  CUSTOM_FULL_BLEED_IMAGE = 'customFullBleedImage',
  CUSTOM_CENTERED_IMAGE = 'customCenteredImage',
  FULL_BLEED_VIDEO = 'heroHeadingFullBleedVideoBlock',
}

export enum CAPTION_SIZE {
  SMALL = 'small',
  LARGE = 'large',
}
