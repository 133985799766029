import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player';
import { always, ifElse, propEq } from 'ramda';

import { media } from '@theme/media';
import { CONTAINER_PADDING, CONTAINER_WIDTH } from '@components/blocks/blocks.constants';
import { linkStyles } from '@components/blocks/blocks.styles';
import { COLORS } from '@theme/colors';
import { FONT_SIZE, FONT_WEIGHT, LETTER_SPACING, LINE_HEIGHT, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

const setColor = ({ color }: { color?: string }) => (color ? `color: ${color}` : null);

export const PlayerControls = styled.div`
  position: absolute;
  left: 22px;
  bottom: 1px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  z-index: 3;
  display: flex;

  ${media.tablet} {
    left: 54px;
    bottom: 22px;
  }
`;

const getContainerStyles = ifElse(
  propEq('isCarousel', true),
  always(css`
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  `),
  always(null)
);

export const Container = styled.div<{
  backgroundColor: string;
  isCarousel?: boolean;
  color?: string | null;
}>`
  width: 100%;
  margin: 0 auto;
  padding-bottom: ${OFFSET.M};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};

  img:nth-of-type(2) {
    margin-top: 50px;
  }
  ${getContainerStyles}
`;

export const FullBleedImage = styled.img`
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
`;

export const CenteredImage = styled.img`
  width: 100%;
  max-width: ${CONTAINER_WIDTH}px;
  height: 100%;
  max-height: calc(100vh - 295px);
  object-fit: contain;
  margin-top: 102px;
`;

export const Masthead = styled.h2<{ color: string }>`
  ${typography({ spacing: LETTER_SPACING.M })};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  max-width: ${CONTAINER_WIDTH}px;
  margin: 0 auto;
  padding: 50px ${CONTAINER_PADDING}px 31px;
  ${setColor};
`;

const headlineStyles = css<{ isLongWord: boolean; color: string }>`
  ${({ isLongWord }) =>
    isLongWord
      ? typography({ size: FONT_SIZE.L, height: LINE_HEIGHT.S })
      : typography({ size: FONT_SIZE.XL, height: LINE_HEIGHT.S })}
  text-align: center;
  bottom: 0;
  padding: 30px 22px;
  width: 100%;
  max-width: ${({ isLongWord }) => (isLongWord ? '726px' : '676px')};
  margin: 0 auto;
  ${setColor};

  ${media.mobile} {
    ${typography({ size: FONT_SIZE.XL, height: LINE_HEIGHT.S })};
    padding: 30px ${CONTAINER_PADDING}px;
    width: auto;
  }

  ${media.desktop} {
    ${typography({ size: FONT_SIZE.XXL, height: LINE_HEIGHT.S })};
  }
`;

export const Headline = styled.h3`
  ${headlineStyles};
  position: sticky;
`;

export const HeadlineOnImage = styled.h3<{ isLongWord: boolean; color: string }>`
  ${headlineStyles};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  text-align: center;

  ${media.mobile} {
    width: 100%;
  }
`;

export const Paragraph = styled.div<{ color: string }>`
  ${typography({ size: FONT_SIZE.M })};
  text-align: center;
  max-width: ${CONTAINER_WIDTH}px;
  margin: 0 auto;
  padding: ${OFFSET.S} ${OFFSET.M} ${OFFSET.L};
  ${setColor};

  a {
    ${linkStyles};
  }
`;

export const ImageWrapper = styled.div<{ wide: boolean }>`
  position: absolute;
  z-index: ${({ wide }) => (wide ? 2 : 'auto')};
  font-size: 0;
  line-height: 0;
  width: 100%;
  text-align: center;
  clip: auto;
  mask-image: -webkit-linear-gradient(top, #fff 0%, #fff 100%);
`;

export const TopContent = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
`;

export const VideoTopContent = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
`;

export const PlayerWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: ${COLORS.BLACK};
  overflow: hidden;
`;

export const Player = styled(ReactPlayer)`
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-aspect-ratio: 16/9) {
      height: 56.25vw !important;
    }
    @media (max-aspect-ratio: 16/9) {
      width: 177.78vh !important;
    }
  }
  pointer-events: none;
`;

export const ControlIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;

  > svg {
    fill: ${COLORS.WHITE};
  }
`;
