import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import SlickSlider, { Settings } from 'react-slick';

import { ArticleSlideValue, ArtworkSlideValue, Slide as SlideType } from '@definitions/homepage.types';
import { CarouselSlide } from '@components/carouselSlide';
import { renderWhenTrue } from '@utils/rendering';
import { COLORS } from '@theme/colors';
import { NARROW_CONTAINER_WIDTH } from '@components/blocks';
import { ARROW_TYPE, LANDMARK_ROLES } from '@utils/constants';

import ArrowLeft from '../../assets/images/carousel-arrow-left.svg';
import ArrowRight from '../../assets/images/carousel-arrow-right.svg';

import { Container, Slider, SliderScrollBar, Thumb } from './heroCarousel.styles';
import { HERO_CAROUSEL_TEST_ID, SLIDER_SPEED, SLIDER_SCROLLBAR_TEST_ID, AUTOPLAY_TIME } from './heroCarousel.constants';
import { CarouselArrow } from './carouselArrow.component';

export interface HeroCarouselProps {
  slides: SlideType[];
}

export const HeroCarousel = ({ slides }: HeroCarouselProps) => {
  const [currentSliderIndex, setCurrentSliderIndex] = useState(0);
  const [arrowVisible, setArrowVisible] = useState<ARROW_TYPE | null>(null);
  const [dragging, setDragging] = useState(false);
  const intl = useIntl();
  const sliderRef = useRef<SlickSlider>(null);
  const slideValue = slides[currentSliderIndex].value;
  const { drawer } = slideValue as ArtworkSlideValue;
  const { textColor } = slideValue as ArticleSlideValue;
  const arrowColor = textColor || drawer?.textColor || COLORS.BLACK;
  const nextSlideLabel = intl.formatMessage({ id: 'heroCarousel.nextSlide', defaultMessage: 'Next Slide' });
  const prevSlideLabel = intl.formatMessage({ id: 'heroCarousel.prevSlide', defaultMessage: 'Previous Slide' });

  const carouselArrows = useMemo(
    () => ({
      prevArrow: (
        <CarouselArrow
          arrowColor={arrowColor}
          arrowType={ARROW_TYPE.PREV}
          arrowVisible={arrowVisible}
          label={prevSlideLabel}
        >
          <ArrowLeft />
        </CarouselArrow>
      ),
      nextArrow: (
        <CarouselArrow
          arrowColor={arrowColor}
          arrowType={ARROW_TYPE.NEXT}
          arrowVisible={arrowVisible}
          label={nextSlideLabel}
        >
          <ArrowRight />
        </CarouselArrow>
      ),
    }),
    [currentSliderIndex, arrowVisible]
  );

  const settings = useMemo(
    () =>
      ({
        dots: true,
        infinite: true,
        autoplay: true,
        arrows: true,
        autoplaySpeed: AUTOPLAY_TIME,
        speed: SLIDER_SPEED,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => handleBeforeChange(next),
        afterChange: () => handleAfterChange(),
      } as Settings),
    [slides]
  );

  const handleBeforeChange = useCallback(
    (next: number) => {
      setCurrentSliderIndex(next);
      setDragging(true);
    },
    [setDragging]
  );

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const renderScrollbar = renderWhenTrue(() => (
    <SliderScrollBar data-testid={SLIDER_SCROLLBAR_TEST_ID}>
      <Thumb slidersCount={slides.length} index={currentSliderIndex} />
    </SliderScrollBar>
  ));

  const handleMouseMove = ({ pageX, currentTarget }: { pageX: number; currentTarget: HTMLDivElement }) => {
    const containerWidth = currentTarget?.offsetWidth;

    if (pageX < containerWidth / 2 - NARROW_CONTAINER_WIDTH) {
      return setArrowVisible(ARROW_TYPE.PREV);
    }

    if (containerWidth / 2 + NARROW_CONTAINER_WIDTH < pageX) {
      return setArrowVisible(ARROW_TYPE.NEXT);
    }

    return setArrowVisible(null);
  };

  return (
    <Container
      data-testid={HERO_CAROUSEL_TEST_ID}
      onMouseMove={handleMouseMove}
      role={LANDMARK_ROLES.BANNER}
      onFocus={() => sliderRef.current?.slickPause()}
      onBlur={() => sliderRef.current?.slickPlay()}
    >
      <Slider {...settings} {...carouselArrows} ref={sliderRef}>
        {slides.map((slide: SlideType) => (
          <CarouselSlide key={slide.id} slide={slide} isDragging={dragging} currentSliderIndex={currentSliderIndex} />
        ))}
      </Slider>
      {renderScrollbar(slides.length > 1)}
    </Container>
  );
};
